import React from 'react';
import classNames from 'classnames';
import Button from '../ui/Button';

type FeatureProps = {
    icon: React.ElementType;
    title: string;
    image: React.ReactElement;
    description?: string;
    children?: React.ReactNode;
    reverse?: boolean;
}

export default function FeatureWithImage({
  title, description, children, icon: Icon, reverse, image,
}:FeatureProps) {
  return (
    <div className="pt-8 pb-12 lg:pt-16 lg:pb-24 relative overflow-hidden">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className={classNames({
          'px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0': true,
          'lg:col-start-2': reverse,
        })}
        >
          <div>
            <div>
              <span className="h-12 w-12 rounded-md flex items-center justify-center bg-primary-600">
                <Icon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-6">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 font-serif">
                {title}
              </h2>
              { children || (
              <p className="mt-4 text-lg text-gray-500">
                {description}
              </p>
              )}
              <div className="mt-6">
                <Button to="/projects">
                  See More
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames({
          'mt-12 sm:mt-16 lg:mt-0': true,
          'lg:col-start-1': reverse,
        })}
        >
          <div className={classNames({
            'lg:px-0 lg:m-0 lg:relative lg:h-full': true,
            'pr-4 sm:pr-6 -ml-48 md:-ml-16': reverse,
            'pl-4 sm:pl-6 -mr-48 md:-mr-16': !reverse,
          })}
          >
            {React.cloneElement(image, {
              className: classNames({
                'feature-image w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:h-full lg:w-auto lg:max-w-none overflow-hidden': true,
                'lg:right-0': reverse,
                'lg:left-0': !reverse,
              }),
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
