import * as React from "react";
import { FaceSmileIcon, HomeIcon } from "@heroicons/react/24/outline";
import { StaticImage } from "gatsby-plugin-image";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/blocks/Hero";
import Stats from "../components/blocks/Stats";
import FeatureWithImage from "../components/blocks/FeatureWithImage";
import { SEO } from "../components/SEO";

// markup
function IndexPage() {
  return (
    <>
      <Header />
      <Hero />
      <Content>
        <div className="sm:text-center lg:text-left">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block lg:inline">A home that will</span>{" "}
            <span className="block text-primary-600 lg:inline">
              enrich your life
            </span>
          </h1>
          <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
            We are passionate about home construction and how it impacts our
            lives. We build homes that bring joy to their owners, and meet the
            everyday needs of their lives.
          </p>
        </div>
      </Content>
      <Stats />
      <FeatureWithImage
        icon={HomeIcon}
        title="Home Builder for All"
        image={<StaticImage src="../images/feature-1.jpg" alt="" />}
        description={`
        At Helin Co, we strive to build the right home for the right people.
        We build custom homes, designer homes, and even remodel homes to
        fit your current need.
      `}
      />
      <FeatureWithImage
        icon={FaceSmileIcon}
        title="Trust &amp; Integrity"
        image={<StaticImage src="../images/feature-2.jpg" alt="" />}
        description="You can trust us at our word. We will tell it to you straight and make sure that expectations are set. We work hard to avoid surprises, and always prioritize each and every one of our relationships."
        reverse
      />
      <Footer />
    </>
  );
}

export default IndexPage;

export const Head = () => <SEO />;
