import React, { useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import videomp4 from "../../images/hero/helin-co-hero.mp4";
import videowebm from "../../images/hero/helin-co-hero.webm";

function Hero() {
  return (
    <div className="relative">
      <div className="absolute inset-x-0 bottom-0 h-1/2 bg-secondary-50" />
      <div className="mx-auto">
        <div className="relative shadow-xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <div className="absolute inset-0">
              <StaticImage
                className="h-full w-full object-cover"
                src="../../images/hero/framing.jpg"
                alt="People working on laptops"
              />
            </div>
            <div className="absolute inset-0">
              <video
                autoPlay
                muted
                loop
                playsInline
                className="h-full w-full object-cover"
              >
                <source src={videowebm} type="video/webm" />
                <source src={videomp4} type="video/mp4" />
              </video>
            </div>

            <div className="absolute inset-0 bg-slate-500 mix-blend-multiply" />
          </div>
          <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
            <h1 className="text-center font-serif text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
              <span className="block text-white opacity-80">We build</span>
              <span className="block text-white">dream homes</span>
            </h1>
            <p className="mx-auto mt-6 max-w-lg text-center text-xl text-slate-200 sm:max-w-2xl">
              Our design &amp; construction process allows for quality,
              beautiful homes. <br />
              We build homes throughout the Twin Cities.
            </p>
            <div className="mx-auto mt-10 max-w-sm sm:flex sm:max-w-none sm:justify-center">
              <div className="space-y-4 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
                <Link
                  to="/projects"
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-primary-700 shadow-sm hover:bg-primary-50 sm:px-8"
                >
                  Projects
                </Link>
                <Link
                  to="/about"
                  className="flex items-center justify-center rounded-md border border-transparent bg-primary-700 bg-opacity-80 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-90 sm:px-8"
                >
                  About Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
