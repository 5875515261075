import classNames from 'classnames';
import React from 'react';

type StatBoxProps = {
    title: string;
    value: string;
    first?: boolean;
    last?: boolean;
}

function StatBox({
  title, value, first, last,
}: StatBoxProps) {
  return (
    <div className={classNames({
      'flex flex-col border-gray-100 p-6 text-center sm:border-0': true,
      'border-t sm:border-l': !first,
      'border-b sm:border-r': !last,
    })}
    >
      <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">{title}</dt>
      <dd className="order-1 text-5xl font-extrabold text-primary-600">{value}</dd>
    </div>
  );
}

export default function Stats() {
  return (
    <div className="bg-secondary-50 pt-12 sm:pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl font-serif">
            Trusted by homeowners across the Twin Cities
          </h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            We strive to build homes for people who want to live well!
          </p>
        </div>
      </div>
      <div className="mt-10 pb-12 bg-white sm:pb-16">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-secondary-50" />
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                <StatBox title="Dream Homes" value="30+" first />
                <StatBox title="Nails" value="387K" />
                <StatBox title="Happy Memories" value="∞" last />
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
